import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  login_credentials = [
    {
      email : 'admin@wegov.com',
		  password: '123456789',
		  role: 'admin',
      redirect_to: 'public'
    },
    {
      email : 'admin@we.gov',
		  password: '123456789',
		  role: 'user',
      redirect_to: 'admin'
    },
  ]
  constructor() { }

  getLoginCred(data) {
    for (let i = 0; i < this.login_credentials.length; i++) {
      if(this.login_credentials[i].email === data.email && this.login_credentials[i].password === data.password) {
        return this.login_credentials[i];
      }
    }
    return undefined;
  }
}
